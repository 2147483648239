import React, { Component } from 'react';
import { Router, Route, IndexRoute, browserHistory } from 'react-router';

class Product extends React.Component {
  
  render() {
      const {match} = this.props;
     console.log(match);
    return (
       
            <div>
                <h2>
                    Hello from Product page
                </h2>
                
                <div><button onClick={browserHistory.goBack}>Go Back</button></div>
            </div>
       
    );
  }
}

export default Product;