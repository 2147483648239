import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import {Link} from 'react-router';
import Product from './Produs';
import Parser from 'html-react-parser';
import LazyLoad from 'react-lazyload';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import $ from 'jquery'; 


class MyComponent extends React.Component {
 
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: []
    };
  }

  componentDidMount() {
        var cat=0;
        if(window.category_id){
          cat=window.category_id;
        }else{
          cat=55;
        }
        if(window.features_hash){
          window.features_hash=window.features_hash;
        }else{
          window.features_hash='';
        }

        if(window.sort_by){
          window.sort_by=window.sort_by;
        }else{
          window.sort_by='timestamp';
        }

        if(window.sort_order){
          window.sort_order=window.sort_order;
        }else{
          window.sort_order='desc';
        }
        if(window.lang){
          window.lang=window.lang;
        }else{
          window.lang='ro';
        }

        if(window.camp_prod_id){
          window.camp_prod_id=window.camp_prod_id;
        }else{
          window.camp_prod_id='';
        }
       /* 
      if(localStorage.getItem("appState"+cat)){
          var sts = JSON.parse(localStorage.getItem("appState"+cat))
          console.log(this.state);
          this.setState(sts);
      }else{
        */
        console.log( cat);
        fetch('list_prods.php?category_id='+cat+'&features_hash='+window.features_hash+'&sort_by='+window.sort_by+'&sort_order='+window.sort_order+'&lang_code='+window.lang+'&camp_prod_id='+window.camp_prod_id) 
                  .then(res => res.json())
                  .then(
                    (result) => {
                      this.setState({
                        isLoaded: true,
                        items: result.items,
                        count: result.count*159,
                        lang_code: window.lang,
                        isLoggedIn: window.isLoggedIn,
                        cat: cat
                      });
                    // localStorage.setItem('appState'+cat, JSON.stringify(this.state));
                    },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    (error) => {
                      this.setState({
                        isLoaded: true,
                        error
                      });
                    }
                  )
              //}
  }

  
          
      

  render() {
   
    const { error, isLoaded, items, count, lang_code,isLoggedIn } = this.state;
    
    const doSaveData = async (product_id) => {
      try {
        console.log(product_id);
        var data_noua= $( ".data_noua_"+product_id ).val();
        console.log(data_noua);
          $.getJSON("index.php?dispatch=categories.save_datanoua", { prid: product_id, data_noua: data_noua, page_limit: 1, page: 1 }, function(data){
                      
           });  
      } catch (err) {
        console.log(err);
      }
    };

    const doSaveColId = async (product_id) => {
      try {
        console.log(product_id);
        var colectie= $( ".colectie_id_"+product_id ).val();
        console.log(colectie);
          $.getJSON("index.php?dispatch=categories.save_colectie_id", { prid: product_id, colectie: colectie, page_limit: 1, page: 1 }, function(data){
                      
                  });  
      } catch (err) {
        console.log(err);
      }
    };

    let moneda;
    let crl;

    if(lang_code=='bg'){
      moneda='лв';
      crl=2.25;
    }else if(lang_code=='hu'){
      moneda='Ft';
      crl=0.0127;
    }else if(lang_code=='el'){
      moneda='€';
      crl=4.2;
    }else if(lang_code=='it'){
      moneda='€';
      crl=4.2;
    }else if(lang_code=='sl'){
      moneda='€';
      crl=4.2;
    }else if(lang_code=='fr'){
      moneda='€';
      crl=4.2;
    }else if(lang_code=='hr'){
      moneda='kn';
      crl=0.6;
    }else{
      moneda='lei';
      crl=1;
    }

    $('.ty-column5777 a').on('click',function(e){
    
      if ( $(this).children().children('.culori_colectii').children().length > 1 ) {
          e.preventDefault();
      
       $('.produse').html('');
      
      var idcol=$(this).children().children().attr('data-id');
     
      $.get("index.php?dispatch=products.produse_colectie", { mode: 'produse_colectie' , idcol:idcol  }, function(data1){ 
          var objs = JSON.parse( data1);
          var html='<h5>'+objs.titlu+'</h5>';
          console.log(objs);
          $.each(objs.items, function(i, item) {
                   html=html+'<a href="index.php?dispatch=products.view&product_id='+item.product_id+'"><div class="sh_item"><img src="'+item.image+'"><span class="sh_title">'+item.culoare+'</span><span class="sh_price">'+item.price+' lei</span></div></a>';
             
          });
          $('.produse').html(html);
  
      });
      $('#tygh_container').addClass('colectie-active');
      var lnk1=$(this).attr('href');
        setTimeout(function(){
              //$('.span13.main-content-grid').removeClass('colectie-active');
              document.location.href=lnk1;
          }, 3000);
      }
  });
  $('.colectie-close').on('click',function(e){
      e.preventDefault();
      $('#tygh_container').removeClass('colectie-active');
  });

  const isLoggedIn1='true';

    const rows = [];
    for (var i = 1; i < 4; i++) {
        rows.push(<div><div style={{ width:'48%', marginRight: 10, display:'inline-block' }}>
          <Skeleton width={'100%'} height={220} duration={0.2}/><br/>
          <div style={{height:6}}></div>
          <Skeleton width={'100%'} count={2} duration={0.2}/></div>
          <div style={{ width:'48%',  display:'inline-block' }}>
          <Skeleton width={'100%'} height={220} duration={0.2}/><br/>
          <div style={{height:6}}></div>
          <Skeleton width={'100%'} count={2} duration={0.2}/></div><br/><br/></div>
          );
    }
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
     
      return <div> 
        <br/>
        <br/>
         {rows}
        </div>;
    } else {
      return (
        
        <div class="grid-list swiper-container">
          <div class="swiper-wrapper">
          {items.map(item => (
          
            <div class={`ty-column5 swiper-slide ${item.produsul_zilei} `}>
            <LazyLoad key={item.product_id} offset={300}>

            <form action="https://devro.zappatos.ro/" method="post" name="product_form_97827" enctype="multipart/form-data" class="cm-disable-empty-files cm-ajax cm-ajax-full-render cm-ajax-status-middle cm-processed-form">
              <input type="hidden" name="result_ids" value="cart_status*,wish_list*,checkout*,account_info*"/>
              <input type="hidden" name="redirect_url" value={`index.php?dispatch=categories.view&category_id=${window.category_id}`}/>
              <input type="hidden" name={`product_data[${item.product_id}][product_id]`} value={item.product_id}/>
              <input type="hidden" name="security_hash" class="cm-no-hide-input" value="02f3d5b93a94313977afb80c54b4e9b5"/>
              <a class="ty-btn ty-btn__text cm-ajax ty-add-to-wish cm-submit text-button1" id={`button_wishlist_${item.product_id}`} href={`/index.php?dispatch=wishlist.add&product_id=${item.product_id}&is_ajax=1`}></a>
            </form>         
            <a href={item.url}>
            
              <div class={`ty-grid-list__item ty-quick-view-button__wrapper a${item.colectie} ty-grid-list__item--overlay`}>
                    <div class="ty-grid-list__image" data-id={item.colectie}>
                    <LazyLoadImage  src={item.image_link} delayTime={50} alt={item.product}/>
                     <div class={`ty-product-labels ty-product-labels--top-right   cm-reload-${item.product_id}`} id={`product_labels_update_${item.product_id}`}>
                     {item.discount > 0 && (
                          <div class="ty-product-labels__item   ty-product-labels__item--discount">
                            <div class="ty-product-labels__content"> {item.discount}</div>
                          </div>
                       )} 
                      </div>
                   
                    </div>
                   
                    {Parser(item.promotii.toString())}
                    {Parser(item.culori)}
                    <div class="ty-grid-list__item-name"><bdi>
                        {item.product}
                      </bdi>
                    </div>
                    <div class="grid-list__rating">    
                    </div>
                    <div class="ty-grid-list__price ">
                    {item.discount > 0 && (
                      <span class={`cm-reload-${item.product_id}`} id={`old_price_update_${item.product_id}`}>
                          <span class="ty-list-price ty-nowrap" id={`line_list_price_${item.product_id}`}><span class="ty-strike"><bdi><span id="sec_list_price_{item.product_id}" class="ty-list-price ty-nowrap">{Math.round(item.list_price/crl)}</span>&nbsp;<span class="ty-list-price ty-nowrap">{moneda}</span></bdi></span></span>
                        
                      </span>
                       )} 
                      <span class={`cm-reload-${item.product_id} ty-price-update`} id={`price_update_${item.product_id}`}>
                          <input type="hidden" name="appearance[show_price_values]" value="1"/>
                          <input type="hidden" name="appearance[show_price]" value="1"/>
                          {item.discount > 0 && (
                          <span class="ty-price red" id={`line_discounted_price_${item.product_id}`}><bdi><span id={`sec_discounted_price_${item.product_id}`} class="ty-price-num">{Math.round(item.price)}</span>&nbsp;<span class="ty-price-num">{moneda}</span></bdi></span>
                          )} 
                          {item.discount < 1 && (
                          <span class="ty-price" id={`line_discounted_price_${item.product_id}`}><bdi><span id={`sec_discounted_price_${item.product_id}`} class="ty-price-num">{Math.round(item.price)}</span>&nbsp;<span class="ty-price-num">{moneda}</span></bdi></span>
                          )} 
                      </span>
                    </div>
                    <div class="marimi">{item.caracteristici}</div>
                   
                    
              </div>
            </a>
            {isLoggedIn==='true' && (
              
              <span class="colls">
                  Cod: <b >{item.product_code}</b><br />
                  Stoc: <b class="stc1" >{item.stoc_total}</b><br />
                  Colectie: <b class="stc1" >{item.colectie}</b><br />
                  Colectie ID: <b class="stc1" >{item.colectie_id}</b><br />
                  Adaos: <b class="stc1" >{item.adaos}</b><br />
                  Data: <b class="dataprod" >{item.data_produs}</b><br />
                  ID Produs: <b class="dataprod" >{item.product_id}</b><br />
                  Vanzari 7/14: <b class="dataprod" >{item.vanzari_7_zile}/{item.vanzari_14_zile}</b><br />
              </span>
               )}
             {isLoggedIn==='true' && (
              <div class="collm">
                  <input type="text"  class={`data_noua_${item.product_id}`} />
                  <button onClick={() => doSaveData(item.product_id)}  onclick="doSaveData({item.product_id})" >Save Data</button>
                  <br/>
                  <input type="text" class={`colectie_id_${item.product_id}`} />
                  <button onClick={() => doSaveColId(item.product_id)} class={`colectie_save_${item.product_id}`} class={`data_noua_${item.product_id}`}  onclick="doSaveColId({item.product_id})">Save Colectie</button>
                  <br/>
                </div>
            )}
            </LazyLoad>
          </div>
          ))}
          
        </div>
        </div>
       
      );
    }
  }
}

export default MyComponent;

